import { Space, notification, Spin, Skeleton, Form, Input, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { fetchSingleBulkPayment, createSingleBulkPaymnet } from './api';

const BulkPaymentCustomer = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [spinning, setSpinning] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    fetchSingleBulkPayment(id).then((e) => {
      console.log(' bulk data', e);
      setResponseData(e['bulkPayment'][0]);
      console.log(e['bulkPayment'][0]);
      setSpinning(false);
    });
  }, []);
  const handleSubmit = () => {
    setButtonLoading(true);
    const { name, email, phoneNo } = data;
    let missingValue = [];

    if (!name) {
      missingValue.push('Name');
    }
    if (!email) {
      missingValue.push('Email ID');
    }
    if (!phoneNo) {
      missingValue.push('phone no');
    }

    if (missingValue.length > 0) {
      let des = missingValue.join(', ');
      notification.error({
        message: `Following field are required`,
        description: `Please enter ${des} details`,
        duration: 3,
      });

      setButtonLoading(false);
      return;
    }

    const { pre_reg_amount, program, link, id } = responseData;

    let inputData = {
      customer_name: name,
      email,
      phone_no: phoneNo,
      pre_reg_amount: pre_reg_amount,
      program_name: program,
      dual_course_name: link,
      bulk_id: id,
    };

    console.log(
      createSingleBulkPaymnet(inputData).then((e) => {
        setButtonLoading(false);
        console.log(e);
        if (e.error) {
          notification.success({
            message: 'Error occured while creating.',
            duration: 3,
          });
          return;
        }
        setData({
          name: '',
          email: '',
          phoneNo: '',
        });

        notification.success({
          message: 'User record created',
          duration: 3,
        });
        const location = window.location;
        window.location.replace(`${location.protocol}//${location.host}/customer/${e}`);
      }),
    );
  };

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNo: '',
  });

  const updateTextFileds = (value, field) => {
    // console.log(value, field);
    setData((prev) => {
      return { ...prev, [field]: value };
    });
  };

  if (!responseData) return <div style={{ backgroundColor: 'white' }}>Link expired / Invalid</div>;

  return (
    <Spin spinning={spinning}>
      {responseData.id ? (
        <>
          <div style={{ height: '90vh' }}>
            <h2
              style={{
                textAlign: 'left',
                borderBottom: '1px solid rgba(0,0,0,0.2)',
                margin: '2% 5%',
              }}
            >
              Enter student details
            </h2>

            <Space>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                // onFinish={handleSubmit}
              >
                <div style={{ paddingTop: '1rem' }}>
                  <lable style={{ fontWeight: 600, marginRight: '2px' }}>Name</lable>{' '}
                  <Form.Item
                    rules={[{ required: true, message: 'Please input customer name!' }]}
                    noStyle={true}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Student name"
                      value={data.name}
                      name="nameOfCustomer"
                      onChange={(e) => updateTextFileds(e.target.value, 'name')}
                    />
                  </Form.Item>
                </div>

                <div style={{ paddingTop: '1rem' }}>
                  <lable style={{ fontWeight: 600, marginRight: '2px' }}>Email</lable>{' '}
                  <Form.Item
                    rules={[{ required: true, message: 'Please input customer name!' }]}
                    noStyle={true}
                  >
                    <Input
                      size="large"
                      name="emailOfStudent"
                      value={data.email}
                      required={true}
                      onChange={(e) => updateTextFileds(e.target.value, 'email')}
                      placeholder="Enter Student email"
                    />
                  </Form.Item>
                </div>

                <div style={{ paddingTop: '1rem' }}>
                  <lable style={{ fontWeight: 600, marginRight: '2px' }}>Phone Number</lable>{' '}
                  <Form.Item
                    noStyle={true}
                    rules={[{ required: true, message: 'Please input customer name!' }]}
                  >
                    <Input
                      size="large"
                      name="phoneNoOfStudent"
                      value={data.phoneNo}
                      required={true}
                      onChange={(e) => updateTextFileds(e.target.value, 'phoneNo')}
                      placeholder="Enter Student Phone No."
                    />
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: 'block',
                    marginTop: '1rem',
                    width: '40%',
                    marginLeft: '30%',
                  }}
                >
                  {' '}
                  <Form.Item noStyle={true}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      className="login-form-button"
                      onClick={handleSubmit}
                      loading={buttonLoading}
                    >
                      Create
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Space>
          </div>
        </>
      ) : (
        <Skeleton active />
      )}
    </Spin>
  );
};

export default BulkPaymentCustomer;
