import { Menu, Layout } from 'antd';
import {
  DashboardOutlined,
  FormOutlined,
  CreditCardOutlined,
  LinkOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import React, { useState } from 'react';

const { Sider } = Layout;

const SideNavbar = ({ collapsed }) => {
  const defaultRoute = () => {
    const pathName = window.location.pathname;

    switch (pathName) {
      case '/employee/dashboard':
        return '1';
      case '/employee/create-link':
        return '2';
      case '/employee/form-status':
        return '3';
      case '/employee/form-bulk-link':
        return '4';
      case '/employee/form-bulk-link-status':
        return '5';
      default:
        return null;
    }
  };

  return (
    <Sider width={collapsed ? 70 : 300} className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={defaultRoute}
        style={{ height: '100%', borderRight: 0, fontWeight: 700, wordSpacing: '0.4rem' }}
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="1">
          <Link to="/employee/dashboard">
            <DashboardOutlined /> {collapsed ? null : 'Dashboard'}
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/employee/create-link">
            <FormOutlined /> {collapsed ? null : 'Create OB Link'}
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/employee/form-status">
            <CreditCardOutlined /> {collapsed ? null : 'OB-Form status'}
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/employee/form-bulk-link">
            <LinkOutlined /> {collapsed ? null : 'OB Bulk Links'}
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/employee/form-bulk-link-status">
            <FileSearchOutlined /> {collapsed ? null : 'OB-Forms Bulk Status'}
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideNavbar;
