import React, { useEffect, useState } from 'react';
import { fetchSinglePayment, fetchCustomer, deleteCustomerBulkLinkWrapper } from './api';
import { Table, notification, Drawer, Row, Col, Spin, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Filter from '../../Components/filter/filter';
import SinglePaymentViewerDrawer from './singlePaymentViewerDrawer';
import { getUser } from '../../Components/auth/firebase';

const SinglePaymentViewer = () => {
  const [data, setData] = useState({ customer_details: [], count: 0 });
  const [isTableLoading, changeIsTableLoading] = useState(true);
  const [sliderData, setSliderData] = useState({});
  const [sliderLoading, changeSlider] = useState(false);
  const [visible, setVisible] = useState(false);
  const currentUser = getUser();

  const onClose = () => {
    setVisible(false);
    setSliderData({});
  };

  useEffect(() => {
    // updating bulk payment link on mounting update with API
    updateTableData({ current: 1, pageSize: 10 });
  }, []);

  const updateTableData = async (pagination, query) => {
    //Load data from DB and update to table
    const { current: page, pageSize: limit } = pagination;

    query = typeof query === 'string' ? query : '';

    changeIsTableLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;

      fetchSinglePayment(token, page, limit, query)
        .then((e) => {
          setData((prev) => {
            return {
              customer_details: e.customer_details,
              count: e.count.aggregate.count,
            };
          });
          changeIsTableLoading(false);
        })
        .catch((e) => {
          changeIsTableLoading(false);
          notification.error({ message: e.message });
        });
    }
    console.log(data);
  };

  const deleteBulkLink = async (id, name) => {
    changeIsTableLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;
      deleteCustomerBulkLinkWrapper(id, token)
        .then((e) => {
          if (e.error) {
            notification.error({ message: e.error });
            changeIsTableLoading(false);
            onClose();
          } else {
            notification.success({ message: `${name ? name : 'Record'} deleted` });
            updateTableData({ current: 1, pageSize: 10 });
            onClose();
          }
        })
        .catch((e) => {
          notification.error({ message: e.message });
          console.error('Error occured', e);
          changeIsTableLoading(false);
          onClose();
        });
    }
  };

  const showDrawer = (id) => {
    changeSlider(true);
    fetchCustomer(id).then((e) => {
      setSliderData(e['customer_details'][0]);
      changeSlider(false);
      setVisible(true);
    });
  };

  const columns = [
    {
      title: 'Customer Details',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (text, record) => (
        <span style={{ fontWeight: 500 }}>
          <span>{text}</span>
          <br />
          <span style={{ opacity: 0.4 }}>
            {record['email']} - {record['phone_no']}
          </span>
        </span>
      ),
    },
    {
      title: 'Course Name',
      dataIndex: 'dual_course_name',
      key: 'dual_course_name',
      render: (text, record) => (
        <span style={{ fontWeight: 500 }}>
          <span>{text}</span>
          <br />
          <span style={{ opacity: 0.4, textOverflow: 'ellipsis' }}>{record['program_name']}</span>
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        <span style={{ fontWeight: 500 }}>
          <span>{new Date(text).toLocaleString('en-in', { dateStyle: 'long' })}</span>,
          <br />
          <span>{new Date(text).toLocaleString('en-in', { timeStyle: 'short' })}</span>
        </span>
      ),
    },
    {
      title: 'Pre-Registration',
      dataIndex: 'pre_reg_amount',
      key: 'pre_reg_amount',
      render: (text, record) => (
        <span style={{ color: text ? (record['is_pre_reg_paid'] ? 'green' : 'red') : '' }}>
          {' '}
          {text ? `₹ ${text}` : 'NA'}
        </span>
      ),
    },
    {
      title: 'Pending Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <span style={{ color: record['is_amount_paid'] ? 'green' : 'red' }}> ₹ {text}</span>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Action',
      dataIndex: 'created_by',
      key: 'created_by',

      render: (text, record) => (
        <>
          <CopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.host}/customer/${record['id']}`,
              );
              clipboardNotification();
            }}
          />
          <span style={{ color: 'blue', marginLeft: '5px' }}>
            <Button type="primary" size="small" onClick={(e) => showDrawer(record['id'])}>
              {' '}
              View
            </Button>
          </span>
        </>
      ),
    },
  ];

  const clipboardNotification = () => {
    notification.success({
      message: 'Link copied to clipboard !',
      duration: 1,
    });
  };

  const filterValue = ['name', 'email', 'phoneno'];
  return (
    <div style={{ minHeight: '100vh' }}>
      <Spin spinning={sliderLoading}>
        <Filter filters={filterValue} updateTableData={updateTableData} offset="1" limit="10" />
        <Table
          pagination={{ total: data.count }}
          columns={columns}
          dataSource={Array.isArray(data.customer_details) ? data.customer_details : []}
          loading={isTableLoading}
          rowKey={(e) => e.id}
          onChange={updateTableData}
        />
      </Spin>
      <Drawer
        title="Customer Details"
        placement={'right'}
        width={500}
        onClose={onClose}
        visible={visible}
      >
        {sliderData ? (
          <SinglePaymentViewerDrawer deleteCallback={deleteBulkLink} sliderData={sliderData} />
        ) : (
          'Loading..'
        )}
      </Drawer>
    </div>
  );
};

export default SinglePaymentViewer;
