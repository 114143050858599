import { Avatar, Layout, Image, Button, Collapse } from 'antd';
import { AlignLeftOutlined, AlignCenterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/logo.png';
import SharkTank from '../../Assets/shark-tank.png';
import { useAuth, logout } from '../../Components/auth/firebase';
import { Popover } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { Row as RowF, Col as ColF } from 'react-flexbox-grid';

const TopNavbar = ({ nav, collapsed, setCollapsed }) => {
  const show = nav ? 'visible' : 'hidden';

  const changeCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  let urlBasePath = window.location.pathname ? window.location.pathname.split('/')[1] : '';
  let iscustomerPhone = document.getElementsByTagName('body')[0].offsetWidth < 500 ? true : false;
  let isCustomerPage = urlBasePath === 'customer' && iscustomerPhone;

  const currentUser = useAuth();

  const text = currentUser ? (
    <span>{currentUser.displayName ? currentUser.displayName : 'Please update your name'}</span>
  ) : (
    'Logout'
  );
  const content = (
    <div>
      <p>
        <Link to="/employee/profile">Profile</Link>
      </p>
      <p>
        <Link to="#" onClick={logout}>
          LogOut
        </Link>
      </p>
    </div>
  );

  if (isCustomerPage)
    return (
      <div
        style={{
          backgroundColor: '#0e0f35',
          color: 'white',
          paddingRight: '1rem',
          borderRadius: '3rem 3rem 0 0',
          fontSize: '1rem',
          fontWeight: 800,
        }}
      >
        <RowF middle={'xs'} around={'xs'}>
          <ColF>
            <Image style={{ flexGrow: 5 }} src={SharkTank} width={90} preview={false} />
          </ColF>
          <ColF>
            <Image style={{ flexGrow: 5 }} src={Logo} width={90} preview={false} />
          </ColF>
          <ColF>
            <span style={{ flexGrow: 5, paddingRight: '1rem', paddingLeft: '1rem' }}>
              {' '}
              <CloseOutlined />{' '}
            </span>
          </ColF>
        </RowF>
      </div>
    );
  else
    return (
      <div
        className="header"
        style={{
          textAlign: 'left',
          display: 'inline-flex',
          alignItems: ' center',
          backgroundColor: '#0a67a2',
          padding: '0 1rem',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Button style={{ visibility: show }} onClick={changeCollapse}>
          {collapsed ? <AlignLeftOutlined /> : <AlignCenterOutlined />}
        </Button>
        <Image style={{ flexGrow: 5 }} src={Logo} width={80} preview={false} />

        <span>
          {currentUser ? (
            <>
              <Popover placement="bottomRight" title={text} content={content} trigger="click">
                <Avatar
                  size={{ sm: 25, md: 30, lg: 32, xl: 40, xxl: 50 }}
                  icon={<UserOutlined />}
                  src={currentUser?.photoURL}
                />
                ,
              </Popover>
            </>
          ) : (
            <Button style={{ visibility: 'hidden' }}>
              {'' ? <AlignLeftOutlined /> : <AlignCenterOutlined />}
            </Button>
          )}
        </span>
      </div>
    );
};

export default TopNavbar;
