import React, { useEffect, useState } from 'react';
import { fetchBulkLinkCustomers, deleteCustomerBulkLinkWrapper } from './api';
import { Table, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getUser } from '../../Components/auth/firebase';
import Filter from '../../Components/filter/filter';
import DeleteModal from '../../Components/delete/deleteModal';

const BulkPaymentViewer = () => {
  const [data, setData] = useState({ customer_details: [], count: 0 });
  const [isTableLoading, changeIsTableLoading] = useState(true);

  const [spinning, changeSpinning] = useState(false);

  const currentUser = getUser();
  console.log('Bulk api', currentUser);

  useEffect(() => {
    // updating bulk payment link on mounting update with API
    updateTableData({ current: 1, pageSize: 10 });
  }, []);

  const updateTableData = async (pagination, query) => {
    //Load data from DB and update to table
    changeIsTableLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;

      query = query ? query : '';

      fetchBulkLinkCustomers(token, pagination, query)
        .then((e) => {
          setData({ customer_details: e.customer_details, count: e.count.aggregate.count });
          changeIsTableLoading(false);
        })
        .catch((e) => {
          notification.error({ message: e.message });
          console.error('Error occured', e);
          changeIsTableLoading(false);
        });
    }
  };

  const deleteBulkLink = async (id, name) => {
    changeIsTableLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;
      deleteCustomerBulkLinkWrapper(id, token)
        .then((e) => {
          if (e.error) {
            notification.error({ message: e.error });
            changeIsTableLoading(false);
          } else {
            notification.success({ message: `${name ? name : 'Record'} deleted` });
            updateTableData({ current: 1, pageSize: 10 });
          }
        })
        .catch((e) => {
          notification.error({ message: e.message });
          console.error('Error occured', e);
          changeIsTableLoading(false);
        });
    }
  };

  const columns = [
    {
      title: 'Customer Details',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (text, record) => (
        <span style={{ fontWeight: 500 }}>
          <span>{text}</span>
          <br />
          <span style={{ opacity: 0.4 }}>
            {record['email']} - {record['phone_no']}
          </span>
        </span>
      ),
    },
    {
      title: 'Course Name',
      dataIndex: 'dual_course_name',
      key: 'dual_course_name',
      render: (text, record) => (
        <span style={{ fontWeight: 500 }}>
          <span>{text}</span>
          <br />
          <span style={{ opacity: 0.4 }}>{record['program_name']}</span>
        </span>
      ),
    },
    {
      title: 'Pre-Registration',
      dataIndex: 'pre_reg_amount',
      key: 'pre_reg_amount',
      render: (text, record) => (
        <span style={{ color: text ? (record['is_pre_reg_paid'] ? 'green' : 'red') : '' }}>
          {' '}
          {text ? `₹ ${text}` : 'NA'}
        </span>
      ),
    },
    {
      title: 'Pending Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <span style={{ color: record['is_pre_reg_paid'] ? 'green' : 'red' }}>
          {record['is_pre_reg_paid'] ? 'Paid' : 'Not Paid'}
        </span>
      ),
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',

      render: (text, record) => (
        <>
          <CopyOutlined
            style={{ fontSize: '1.5rem', marginRight: '2rem' }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.host}/customer/${record['id']}`,
              );
              clipboardNotification();
            }}
          />
        </>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (text, { id, customer_name }) => (
        <DeleteModal id={id} name={customer_name} callback={deleteBulkLink} />
      ),
    },
  ];

  const clipboardNotification = () => {
    notification.success({
      message: 'Link copied to clipboard !',
      duration: 1,
    });
  };

  const filterValue = ['name', 'email', 'phoneno'];

  return (
    <div style={{ minHeight: '100vh' }}>
      <Filter filters={filterValue} updateTableData={updateTableData} offset="1" limit="10" />
      <Table
        columns={columns}
        dataSource={Array.isArray(data.customer_details) ? data.customer_details : []}
        loading={isTableLoading}
        rowKey={(e) => e.id}
        pagination={{
          total: data.count,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50, 100],
        }}
      />
    </div>
  );
};

export default BulkPaymentViewer;
