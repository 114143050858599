import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';

const FIREBASE_API_KEY = process.env.REACT_APP_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

console.log('firebase', app);

const auth = getAuth();

export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

// Custom Hook
export function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);

  return currentUser;
}

export function getUser() {
  return auth.currentUser;
}

export function emailVerification(user) {
  return sendEmailVerification(user);
}

export function updateUserInfo(displayName) {
  return updateProfile(auth.currentUser, { displayName: displayName });
}
