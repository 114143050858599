import { Result, Button, Row, Col, Space, Divider, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { fetchCustomer, fetchOrder, setOrder } from './api';
import { CheckCircleFilled, PrinterOutlined } from '@ant-design/icons';
import { Redirect, Navigate, Route, Routes } from 'react-router-dom';

import { useLocation, useParams } from 'react-router';

const CustomerPaymentSuccess = () => {
  const [data, setData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [redirectToPay, setredirect] = useState(false);

  const { id } = useParams();

  useEffect((e) => {
    setDataLoading(true);
    setTimeout(async()=>{
      fetchCustomer(id.split("_")[0])
      .then((e) => {
        if(!e)  setredirect(true)
        setData(e['customer_details_by_pk']);
        setDataLoading(false);
        console.log(e);
      })
      .catch((err) => {
        setDataLoading(false);
        setredirect(true)
        setIsValidUrl(false);
      });
    }, 1000*10)
    
    console.log(id);
  }, []);

  const getTitle=()=>{
    const  {is_amount_paid,  is_full_amount,  is_pre_reg_paid} = data;

    if(is_amount_paid ) return "You have been On-Boarded Successfully!"
    if(is_pre_reg_paid) return "Your Pre Registration was Successfull!"
  }

  const getSubTitle = ()=>{
    const  {is_amount_paid,  is_full_amount,  is_pre_reg_paid} = data;
    if(is_amount_paid) return "You will get the further instruction in 2-3 Business Days."
    if(is_pre_reg_paid) return "You can proceed for full payment or contact support for further steps."

    
  }

  return (
    <div style={{ minHeight: '90vh' }}>
      {redirectToPay && <Route path="*" element={<Navigate replace to={`/customer/${id}`} />} />}
      {(dataLoading || data==={})?<Spin spinning={true} size={'large'} style={{ marginTop: '10%' }} tip="Loading..."></Spin>:
      <><Result
        style={{ alignItems: 'center' }}
        className="no-print"
        status="success"
        title={getTitle()}
        subTitle={getSubTitle()}
      />
      <div className="no-screen">
        <CheckCircleFilled style={{ color: '#00cc00', fontSize: '3rem' }} />
        <h2>Thanks for your intrest!</h2>
        <p>You will get the further instruction in 2-3 Business Days. </p>
      </div>
      <>
        <div
          style={{
            textAlign: 'left',
            marginLeft: '20%',
            marginRight: '20%',
            marginBottom: '1rem',
          }}
        >
          <Divider orientation="left">Customer Details</Divider>
          <Row>
            <Col span={12}>Name : </Col>
            <Col span={12}>{data.customer_name}</Col>
            <Col span={12}>Email Id : </Col>
            <Col span={12}>{data.email}</Col>
            <Col span={12}>Phone No : </Col>
            <Col span={12}>{data.phone_no}</Col>
          </Row>

          <Divider orientation="left">Program Details</Divider>
          <Row>
            <Col span={12}>Dual / Course Name : </Col>
            <Col span={12}>{data.dual_course_name}</Col>
            <Col span={12}>Program : </Col>
            <Col span={12}>{data.program_name}</Col>

            <>
              {data.customer_course && !data.bulk
                ? data.customer_course.map((courseData, index) => {
                    const indexName = index === 0 ? 'One' : 'Two';
                    return (
                      <>
                        <Col span={12}>Course {indexName} :</Col>
                        <Col span={12}>{courseData.course}</Col>

                        {courseData.program_plan ? (
                          <>
                            <Col span={12}>Plan {indexName} :</Col>
                            <Col span={12}>{courseData.program_plan}</Col>
                          </>
                        ) : null}

                        {courseData.batch_month ? (
                          <>
                            <Col span={12}>Batch {indexName} :</Col>
                            <Col span={12}>{courseData.batch_month}</Col>
                          </>
                        ) : null}
                      </>
                    );
                  })
                : null}
            </>
          </Row>

          <Divider orientation="left">Transaction Details</Divider>
          {data.customer_transaction
            ? data.customer_transaction.map((e, index) => (
                <div style={{ marginBottom: '1rem' }}>
                  <h3> Payment </h3>

                  <Row>
                    <Col span={12}>Purchase ID : </Col>
                    <Col span={12}>{e.id}</Col>
                    <Col span={12}>Amount (Rs.) </Col>
                    <Col span={12}>{parseFloat(e.amount)}</Col>
                    <Col span={12}>Date and Time </Col>
                    <Col span={12}>{new Date(e.created_at).toLocaleString()}</Col>
                    
                    <Col span={12}>{`Payment Gateway ${e.gateway} ID : `}</Col>
                    <Col span={12}>{e.transaction_id}</Col>
                  </Row>
                </div>
              ))
            : 'null'}
        </div>
      </>
      <Button
        className="no-print"
        icon={<PrinterOutlined />}
        style={{ margin: '2rem 0' }}
        size={'large'}
        type="primary"
        onClick={(e) => window.print()}
      >
        Print
      </Button>
    </>}
    </div>
  );
};

export default CustomerPaymentSuccess;
