import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Space, Button, Modal, Form, Input, Checkbox, Spin, notification } from 'antd';
import { useLocation, useParams } from 'react-router';
import { Redirect, Navigate, Route, Routes } from 'react-router-dom';
import { fetchCustomer, fetchOrder, setOrder , fetchPaymentLink} from './api';
import Media from 'react-media';
import { ArrowRightOutlined } from '@ant-design/icons';
import './customerPayment.css';

const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

const CustomerPaymentPage = () => {
  //Modal

  const [data, setData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  let { id } = useParams();

  useEffect((e) => {
    setDataLoading(true);
    console.log(id.split("_")[0], "Url ")
    fetchCustomer(id.split("_")[0])
      .then((e) => {
        setData(e['customer_details_by_pk']);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        setIsValidUrl(false);
      });
      console.log("Hari")
    console.log(id.split("_")[0]);
  }, []);
  //form functions

  const getCurrentPayment = () => {
    if (!data) return null;
    if (data.bulk) return data.customer_bulk[0].pre_reg_amount;
    if (!data.is_full_amount && !data.is_pre_reg_paid) return data.pre_reg_amount;
    return data.amount;
  };
  const currentAmount = getCurrentPayment();
  const gatewayCharges = (currentAmount / 100) * 2;

  const handelPaymentSuccess = (response, id, amount) => {
    notification.success({
      message: 'Payment success...!',
      duration: 3,
    });

    // const data = {
    //   amount,
    //   order_id: response.razorpay_order_id,
    //   signature: response.razorpay_signature,
    //   transaction_id: response.razorpay_payment_id,
    //   user_id: id,
    // };
    // setOrder(data).then((e) => {
    //   console.log(e);
    //   window.location.reload();
    // });
  };

  // const displayRazorpay = async () => {
  //   setDataLoading(true);
  //   function loadScript(src) {
  //     return new Promise((resolve) => {
  //       const script = document.createElement('script');
  //       script.src = src;
  //       script.onload = () => {
  //         resolve(true);
  //       };
  //       script.onerror = () => {
  //         resolve(false);
  //       };
  //       document.body.appendChild(script);
  //     });
  //   }

  //   // const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  //   if (!res) {
  //     alert('Razorpay SDK failed to load. Are you online?');
  //     return;
  //   }

  //   // creating a new order
  //   // const result = await fetchOrder(currentAmount + gatewayCharges, {
  //   //   name: data.customer_name,
  //   // });

  //   if (!result) {
  //     alert('Server error. Are you online?');
  //     return;
  //   }

  //   // Getting the order details back
  //   console.log(result);
  //   const { link_url } = result.data;

  //   // const options = {
  //   //   key: REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
  //   //   amount: amount.toString(),
  //   //   currency: currency,
  //   //   name: 'Mentor Box',
  //   //   description: `Transaction is for ${data.customer_name}`,
  //   //   order_id: order_id,
  //   //   handler: async function (response) {
  //   //     handelPaymentSuccess(response, id, amount);
  //   //   },
  //   //   modal: {
  //   //     ondismiss: function () {
  //   //       setDataLoading(false);
  //   //     },
  //   //   },
  //   //   prefill: {
  //   //     name: data.customer_name,
  //   //     email: data.email,
  //   //     contact: '7811964208',
  //   //   },
  //   //   notes: {
  //   //     address: 'EventBeep Entertainment, Pune',
  //   //   },
  //   //   theme: {
  //   //     color: '#61dafb',
  //   //   },
  //   //   ondismiss: async function (response) {
  //   //     console.log(response);
  //   //   },
  //   // };

  //   // const paymentObject = new window.Razorpay(options);

  //   // paymentObject.open();

  //   // paymentObject.on('payment.failed', ({ error }) => {
  //   //   console.log(error);

  //     // notification.success({
  //     //   message: 'Payment failure',
  //     //   description: `${error.description} : ${error.metaData.payment_id}`,
  //     // });
  //   // });
  // };

  const capitalize = (input) => {
    if (!input) return input;
    if (input.length < 1) return input;

    return input.slice(0, 1).toUpperCase() + input.slice(1);
  };

  const capitalizeAll = (input) => {
    let stringArr = input.split(' ');

    stringArr = stringArr.map((item) => capitalize(item));

    return stringArr.join(' ');
  };

  const fetchUrl = async()=>{
    try{
    const realId = id.split("_")[0];
    setDataLoading(true)
    const data = await fetchPaymentLink(realId)
    if(data.data && data.data.link_url) window.location=data.data.link_url
    console.log(data);
    }catch(error){
      console.log(error)
    }
    setDataLoading(false)
  }

  return (
    <div
      style={{
        minHeight: '90vh',
        lineHeight: '3rem',
        transform: 'translateY(-1px)',
      }}
      className="cust"
    >
      <Spin spinning={dataLoading} size={'large'} style={{ marginTop: '10%' }} tip="Loading...">
        {data['is_amount_paid'] || (data.bulk && data.is_pre_reg_paid) ? (
          <Routes>
            {' '}
            <Route path="*" element={<Navigate replace to={`/customer/success/${data.id}`} />} />
          </Routes>
        ) : (
          ''
        )}
        {data ? (
          data['code'] === 'data-exception' ? (
            <div> Please enter valid url</div>
          ) : !dataLoading ? (
            <Media
              queries={{
                small: '(max-width: 500px)',
                large: '(min-width: 501px)',
              }}
            >
              {(matches) => (
                <Fragment>
                  {matches.large && (
                    <Row style={{ height: '100% !important', margin: '3rem 5rem' }}>
                      <Col sm={24} md={12}>
                        <h2>Student Details</h2>
                        <Space>
                          <Row style={{ textAlign: 'left' }}>
                            <Col span={12} style={{ fontWeight: '700' }}>
                              Student Name :
                            </Col>
                            <Col span={12}>{data.customer_name ? data.customer_name : ''}</Col>
                            <Col span={12} style={{ fontWeight: '700' }}>
                              Email Id :
                            </Col>
                            <Col span={12}>{data.email ? data.email : ' '}</Col>
                            <Col span={12} style={{ fontWeight: '700' }}>
                              Phone No :
                            </Col>
                            <Col span={12}>{data.phone_no ? data.phone_no : ' '}</Col>

                            <Col span={12} style={{ fontWeight: '700' }}>
                              Total Amount :
                            </Col>
                            <Col span={12}>
                              {data.bulk
                                ? data.customer_bulk[0].pre_reg_amount
                                : data.pre_reg_amount != ''
                                ? parseInt(data.pre_reg_amount) + parseInt(data.amount)
                                : parseInt(data.amount)}
                            </Col>
                            {data.bulk ? null : (
                              <>
                                <Col span={24}>
                                  <h3 style={{ marginTop: '1rem' }}>Course Details</h3>
                                </Col>

                                {data.customer_course && !data.bulk
                                  ? data.customer_course.map((courseData, index) => {
                                      const indexName = index === 0 ? 'One' : 'Two';
                                      return (
                                        <>
                                          <Col span={12} style={{ fontWeight: '700' }}>
                                            Course {indexName} :
                                          </Col>
                                          <Col span={12}>{courseData.course}</Col>

                                          {courseData.program_plan ? (
                                            <>
                                              <Col span={12} style={{ fontWeight: '700' }}>
                                                Plan {indexName} :
                                              </Col>
                                              <Col span={12}>{courseData.program_plan}</Col>
                                            </>
                                          ) : null}

                                          {courseData.batch_month ? (
                                            <>
                                              <Col span={12} style={{ fontWeight: '700' }}>
                                                Batch {indexName} :
                                              </Col>
                                              <Col span={12}>{courseData.batch_month}</Col>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })
                                  : 'No Coures Listed'}
                              </>
                            )}
                          </Row>
                        </Space>
                      </Col>
                      <Col sm={24} md={12}>
                        <h2>Billing Info</h2>
                        <Row style={{ textAlign: 'left' }}>
                          <Col span={12} style={{ fontWeight: '700' }}>
                            Program Name :
                          </Col>
                          <Col span={12}>{data.program_name}</Col>

                          {!data.is_pre_reg_paid && !data.is_full_amount ? (
                            <>
                              <Col span={12} style={{ fontWeight: '700' }}>
                                Pre-Registration :
                              </Col>
                              <Col span={12}>{data.pre_reg_amount}</Col>
                            </>
                          ) : (
                            <>
                              <Col span={12} style={{ fontWeight: '700' }}>
                                Amount :
                              </Col>
                              <Col span={12}>{data.amount}</Col>
                            </>
                          )}

                          <Col span={12} style={{ fontWeight: '700' }}>
                            Gateway Charges :
                          </Col>
                          <Col span={12}>{gatewayCharges}</Col>
                          <Col
                            span={12}
                            style={{
                              fontWeight: '700',
                              borderTop: '2px solid #001529',
                              marginTop: '1rem',
                              paddingTop: '1.5rem',
                            }}
                          >
                            Pay Grand Total :
                          </Col>
                          <Col
                            span={12}
                            style={{
                              borderTop: '2px solid #001529',
                              marginTop: '1rem',
                              paddingTop: '1.5rem',
                            }}
                          >
                            {gatewayCharges + currentAmount}
                          </Col>
                        </Row>
                        <Button
                          type="primary"
                          style={{ margin: '2rem 0', width: '60%' }}
                          disabled={dataLoading}
                          onClick={fetchUrl}
                        >
                          Pay Now
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {matches.small && (
                    <Row
                      style={{
                        height: '100vh',
                        margin: '0rem 0rem',
                        backgroundColor: '#0e0f35',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      <Col sm={24} md={12}>
                        <div>
                          <h2 style={{ color: 'white' }}>Program Details</h2>
                          <div className="bottomDash topSection">
                            <div style={{ textAlign: 'center' }}>
                              <div style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                                {' '}
                                {data ? capitalize(data.program_name) : null} Program
                              </div>
                              {data.customer_course && !data.bulk
                                ? data.customer_course.map((courseData, index) => {
                                    return (
                                      <div style={{ fontWeight: 'bold' }}>
                                        {courseData.program_plan ? (
                                          <span>{capitalizeAll(courseData.program_plan)}</span>
                                        ) : null}{' '}
                                        - <span>{capitalizeAll(courseData.course)}</span>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </div>

                        <div className="section bottomDash">
                          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>Details</div>
                          <div className="entry">
                            <div className="field">Name</div>
                            <div className="fieldValue">
                              {data.customer_name ? data.customer_name : ''}
                            </div>
                          </div>
                          <div className="entry">
                            <div className="field">Contact No</div>
                            <div className="fieldValue">{data.phone_no ? data.phone_no : ' '}</div>
                          </div>
                          <div className="entry">
                            <div className="field">Total Fees</div>
                            <div className="fieldValue">
                              {data.bulk
                                ? data.customer_bulk[0].pre_reg_amount
                                : data.pre_reg_amount != ''
                                ? parseInt(data.pre_reg_amount) + parseInt(data.amount)
                                : parseInt(data.amount)}
                            </div>
                          </div>
                          {data.customer_course && data.customer_course[0] && (
                            <div>
                              <div className="field">Batch</div>
                              <div className="fieldValue">
                                {data.customer_course && data.customer_course[0] ? (
                                  <>{capitalize(data.customer_course[0].batch_month)}</>
                                ) : (
                                  'N/A'
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="section">
                          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>Fees</div>

                          {!data.is_pre_reg_paid && !data.is_full_amount ? (
                            <>
                              <div className="field">Pre-Registration :</div>
                              <div className="fieldValue">{data.pre_reg_amount}</div>
                            </>
                          ) : (
                            <>
                              <div className="field">Amount </div>
                              <div className="fieldValue">{data.amount}</div>
                            </>
                          )}

                          <div className="field">Gateway fee </div>
                          <div className="fieldValue">{gatewayCharges}</div>

                          <div className="field">Pay Now</div>
                          <div className="fieldValue">{gatewayCharges + currentAmount}</div>
                        </div>

                        <Button
                          type="primary"
                          style={{
                            margin: '2rem 0',
                            padding: '1rem 2rem 2.5rem 2rem',
                            borderRadius: '1rem',
                            backgroundColor: '#6958d6',
                            borderColor: '#6958d6',
                          }}
                          onClick={fetchUrl}
                        >
                          <span
                            style={{ marginTop: '-1rem', fontWeight: 'bold', fontSize: '1.1rem' }}
                            disabled={dataLoading}
                          >
                            Pay Now <ArrowRightOutlined />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
            </Media>
          ) : null
        ) : (
          <>Loading ...</>
        )}
      </Spin>
    </div>
  );
};

export default CustomerPaymentPage;
