import { Button, notification, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { useAuth, login, emailVerification, updateUserInfo } from '../../Components/auth/firebase';
import TopNavbar from '../../Components/navigation/topNavbar';
import { Link } from 'react-router-dom';

const Profile = () => {
  const [data, setData] = useState({});

  const currentUser = useAuth();

  useEffect(() => {
    if (currentUser && !currentUser.emailVerified) {
      notification.error({ message: 'Please verify your Email' });
    }
  }, [currentUser]);

  console.log('currentUser', currentUser);

  const onFinish = (values) => {
    console.log(values);

    let displayName = values.displayName;

    if (displayName === '') {
      notification.error({ message: 'Please enter your name' });
      return;
    }
    updateUserInfo(displayName)
      .then((e) => {
        notification.success({ message: 'Username updated..!' });
        window.location.reload();
      })
      .catch((e) => console.log(e));

    console.log(displayName);
  };

  return (
    <div style={{ minHeight: '80vh' }}>
      {currentUser ? (
        <div>
          <h1 style={{ fontWeight: 700, fontSize: '2rem' }}>Profile</h1>
          <div
            style={{
              textAlign: 'left',
              lineHeight: '2rem',
              marginLeft: ' 10%',
            }}
          >
            <div>
              {' '}
              <span style={{ fontWeight: 500 }}>Name :</span>{' '}
              <span>{currentUser.displayName ? currentUser.displayName : 'Not updated   '}</span>{' '}
            </div>
            <div>
              {' '}
              <span style={{ fontWeight: 500 }}>User Email ID :</span>{' '}
              <span>{currentUser.email}</span>{' '}
            </div>
            <div>
              {' '}
              <span style={{ fontWeight: 500 }}>Phone No :</span>{' '}
              <span>{currentUser.phoneNo ? currentUser.phoneNo : 'Not updated   '}</span>{' '}
            </div>
            <div>
              {' '}
              <span style={{ fontWeight: 500 }}>Is User verified :</span>{' '}
              <span>{currentUser.emailVerified ? 'Verifed' : 'Not verified'}</span>{' '}
              <span>
                {' '}
                {currentUser.emailVerified ? null : (
                  <Button
                    type="primary"
                    size="sm"
                    onClick={(e) =>
                      emailVerification(currentUser)
                        .then((e) => notification.success({ message: 'Verification Email sent' }))
                        .catch((e) => {
                          if (e.code === 'auth/too-many-requests') {
                            notification.error({ message: 'Verification mail already sent' });
                          }
                        })
                    }
                  >
                    Verify Email
                  </Button>
                )}{' '}
              </span>
            </div>
          </div>

          <div
            style={{
              width: '50%',
              marginRight: 'auto',
              marginLeft: 'auto',
              paddingTop: '10%',
              display: currentUser.displayName ? 'none' : 'visible',
            }}
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              style={{ aligItems: 'center' }}
            >
              <Form.Item name="displayName">
                <Input placeholder="Your Name" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" block>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        'You are logged out'
      )}
    </div>
  );
};

export default Profile;
