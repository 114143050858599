import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Divider, Statistic } from 'antd';

import { fetchDashboardRevenueAllTime } from './api';
//last 30 days - new Date(new Date().setHours(0,0,0,0) - (89280000*30))
const Dashboard = () => {
  const [data, setData] = useState({
    bulkLinkCustomersEnrolledAllTimeCount: 0,
    bulkPaymentCountAllTimeCount: 0,
    paidFullAmountAllTimeAmount: 0,
    paidFullAmountTodayAmount: 0,
    paidPreRegistrationAllTimeAmount: 0,
    paidPreRegistrationTodayAmount: 0,
    pendingFullAmountAllTimeAmount: 0,
    pendingFullAmountTodayAmount: 0,
    pendingFullAmountTodayCount: 0,
    pendingPreRegistrationAllTimeAmount: 0,
    pendingPreRegistrationTodayAmount: 0,
    pendingPreRegistrationTodayCount: 0,
    singleLinkCountAllTimeCount: 0,
    singleLinkCountTodayCount: 0,
    bulkLinkCustomersEnrolledTodayCount: 0,
    bulkPaymentCountTodayCount: 0,
    paidFullAmountMonthAmount: 0,
    paidPreRegistrationMonthAmount: 0,
    pendingFullAmountMonthAmount: 0,
    pendingFullAmountMonthCount: 0,
    pendingPreRegistrationMonthAmount: 0,
    pendingPreRegistrationMonthCount: 0,
    singleLinkCountMonthCount: 0,
    bulkLinkCustomersEnrolledMonthCount: 0,
    bulkPaymentCountMonthCount: 0,
  });
  const [loading, setLoading] = useState(true);

  const {
    bulkLinkCustomersEnrolledAllTimeCount,
    bulkPaymentCountAllTimeCount,
    paidFullAmountAllTimeAmount,
    paidFullAmountTodayAmount,
    paidPreRegistrationAllTimeAmount,
    paidPreRegistrationTodayAmount,
    pendingFullAmountAllTimeAmount,
    pendingFullAmountTodayAmount,
    pendingFullAmountTodayCount,
    pendingPreRegistrationAllTimeAmount,
    pendingPreRegistrationTodayAmount,
    pendingPreRegistrationTodayCount,
    singleLinkCountAllTimeCount,
    singleLinkCountTodayCount,
    bulkLinkCustomersEnrolledTodayCount,
    bulkPaymentCountTodayCount,
    paidFullAmountMonthAmount,
    paidPreRegistrationMonthAmount,
    pendingFullAmountMonthAmount,
    pendingFullAmountMonthCount,
    pendingPreRegistrationMonthAmount,
    pendingPreRegistrationMonthCount,
    singleLinkCountMonthCount,
    bulkLinkCustomersEnrolledMonthCount,
    bulkPaymentCountMonthCount,
  } = data;

  useEffect((e) => {
    fetchDashboardRevenueAllTime().then((e) => {
      setData((prev) => ({ ...prev, ...e }));
      setLoading(false);
      console.log('new data', e);
    });
  }, []);
  return (
    <div style={{ minHeight: '82vh', fontWeight: 600 }}>
      {data ? (
        <>
          <h1>Dashboard</h1>
          <>
            {' '}
            <div>
              <Divider orientation="left">Today</Divider>
              <Row
                gutter={[
                  { xs: 9, sm: 17, md: 25, lg: 33 },
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                ]}
                align="middle"
              >
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="OB / Single Link customers"
                      value={singleLinkCountTodayCount ? singleLinkCountTodayCount : '0'}
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk link Customers"
                      value={
                        bulkLinkCustomersEnrolledTodayCount
                          ? bulkLinkCustomersEnrolledTodayCount
                          : '0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'green' }}>
                    <Statistic
                      title="Revenue generated"
                      value={
                        paidFullAmountTodayAmount + paidPreRegistrationTodayAmount
                          ? `₹ ${paidFullAmountTodayAmount + paidPreRegistrationTodayAmount}`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>

                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'red' }}>
                    <Statistic
                      title="Pending payment"
                      value={
                        pendingFullAmountTodayAmount + pendingPreRegistrationTodayAmount
                          ? `₹ ${pendingFullAmountTodayAmount + pendingPreRegistrationTodayAmount}`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk links created"
                      value={bulkPaymentCountTodayCount ? bulkPaymentCountTodayCount : 'Not Found'}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <div>
              <Divider orientation="left">This Month</Divider>
              <Row
                gutter={[
                  { xs: 9, sm: 17, md: 25, lg: 33 },
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                ]}
                align="middle"
              >
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="OB / Single Link customers"
                      value={singleLinkCountMonthCount ? singleLinkCountMonthCount : '0'}
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk link Customers"
                      value={
                        bulkLinkCustomersEnrolledMonthCount
                          ? bulkLinkCustomersEnrolledMonthCount
                          : '0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'green' }}>
                    <Statistic
                      title="Revenue generated"
                      value={
                        paidFullAmountMonthAmount + paidPreRegistrationMonthAmount
                          ? `₹ ${paidFullAmountMonthAmount + paidPreRegistrationMonthAmount}`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>

                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'red' }}>
                    <Statistic
                      title="Pending payment"
                      value={
                        pendingFullAmountMonthAmount + pendingPreRegistrationMonthAmount
                          ? `₹ ${pendingFullAmountMonthAmount + pendingPreRegistrationMonthAmount}`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk links created"
                      value={bulkPaymentCountMonthCount ? bulkPaymentCountMonthCount : 'Not Found'}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <div>
              <Divider orientation="left">All(Lifetime)</Divider>
              <Row
                gutter={[
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                  { xs: 8, sm: 16, md: 24, lg: 32 },
                ]}
              >
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="OB / Single Link customers"
                      value={singleLinkCountAllTimeCount ? singleLinkCountAllTimeCount : '0'}
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk link Customers"
                      value={
                        bulkLinkCustomersEnrolledAllTimeCount
                          ? bulkLinkCustomersEnrolledAllTimeCount
                          : '0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'green' }}>
                    <Statistic
                      title="Revenue generated"
                      value={
                        paidFullAmountAllTimeAmount + paidPreRegistrationAllTimeAmount
                          ? `₹ ${paidFullAmountAllTimeAmount + paidPreRegistrationAllTimeAmount}`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>

                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300, color: 'red' }}>
                    <Statistic
                      title="Pending payment"
                      value={
                        pendingFullAmountAllTimeAmount + pendingPreRegistrationAllTimeAmount
                          ? `₹ ${
                              pendingFullAmountAllTimeAmount + pendingPreRegistrationAllTimeAmount
                            }`
                          : '₹ 0'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic
                      title="Bulk links created"
                      value={
                        bulkPaymentCountAllTimeCount ? bulkPaymentCountAllTimeCount : 'Not Found'
                      }
                    />
                  </Card>
                </Col>
                <Col>
                  <Card loading={loading} bordered={false} style={{ width: 300 }}>
                    <Statistic title="Team Members" value={3} />
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </>
      ) : null}
    </div>
  );
};

export default Dashboard;
