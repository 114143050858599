const BACKEND_HASURA_URL = process.env.REACT_APP_HASURA_URL;
const BACKEND_URL = process.env.REACT_APP_BASE_URL;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

async function fetchCustomerDetails(id) {
  console.log(id, id.split("_")[0], "url");
  id = id.split("_")[0];
  const result = await fetch(`${BACKEND_HASURA_URL}/customer/${id}`, {
    method: 'get',
  });
  console.log(result.body);
  return await result.json();
}

export async function fetchCustomer(id) {
  try {
    let data = await fetchCustomerDetails(id);

    return data;
  } catch (e) {
    throw e;
  }
}

async function fetchOrderDetails(amount, metaData) {
  console.log(amount, metaData);
  const result = await fetch(`${BACKEND_URL}/order`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount,
      metaData,
    }),
  });
  console.log(result.body);
  return await result.json();
}

export async function fetchOrder(amount, metaData) {
  try {
    let data = await fetchOrderDetails(amount, metaData);

    return data;
  } catch (e) {
    throw e;
  }
}

async function setOrderDetails(data) {
  const { amount, order_id, signature, transaction_id, user_id } = data;
  const result = await fetch(`${BACKEND_HASURA_URL}/payment/success`, {
    method: 'post',
    body: JSON.stringify({
      amount,
      gateway: 'Razorpay',
      order_id,
      signature,
      transaction_id,
      user_id,
    }),
  });
  console.log(result.body);
  return await result.json();
}

export async function setOrder(data) {
  try {
    let response = await setOrderDetails(data);

    return response;
  } catch (e) {
    throw e;
  }
}

async function fetchPaymentLinkTask(id) {
  
  const result = await fetch(`${REACT_APP_BASE_URL}/cashfree/order/${id}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  });

  console.log(result.body);
  return await result.json();
}



export async function fetchPaymentLink(id) {
  try {
    let data = await fetchPaymentLinkTask(id);

    return data;
  } catch (e) {
    throw e;
  }
}