import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Drawer } from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Affix, Avatar, notification } from 'antd';
import { createBulkPaymnet, fetchBulkPayment, deleteBulkLinkWrapper } from './api';

import { getUser } from '../../Components/auth/firebase';
import DeleteModal from '../../Components/delete/deleteModal';

const { Option } = Select;

const BulkPayment = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Link Type',
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: 'Program Name',
      dataIndex: 'program',
      key: 'program',
    },
    {
      title: 'Pre-registration Amt.',
      dataIndex: 'pre_reg_amount',
      key: 'pre_reg_amount',
      render: (text) => <span>₹ {text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <span>{text ? 'Active' : 'Deactivated'}</span>,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },

    {
      title: 'Copy',
      key: 'link',
      render: (text, record) => (
        <span style={{ fontSize: '1.5rem' }}>
          <CopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(
                text
                  ? `${window.location.protocol}//${window.location.host}/customer/details/${text['id']}`
                  : '',
              );
              clipboardNotification();
            }}
          />
        </span>
      ),
    },

    {
      title: 'Delete',
      key: 'delete',
      render: (text, { id, name }) => <DeleteModal id={id} name={name} callback={deleteBulkLink} />,
    },
  ];

  const clipboardNotification = () => {
    notification.success({
      message: 'Link copied to clipboard !',
      duration: 1,
    });
  };

  const bulkLinkCreationNotificaton = () => {
    notification.success({
      message: 'Bulk Link created !',
      duration: 3,
    });
  };

  const [data, setData] = useState({ bulkPayment: [], count: 0 }); // Table data
  const [visible, setVisible] = useState(false); // slider visiblity
  const [loading, setLoading] = useState(true);
  const [sliderState, setSliderState] = useState({
    // slider form data
    preRegistrationAmount: '1000',
    linkType: 'Regular',
    programName: 'Certification',
    name: '',
  });

  const currentUser = getUser();

  useEffect(() => {
    // updating bulk payment link on mounting update with API
    updateTableData({ current: 1, pageSize: 10 });
  }, []);

  const updateTableData = async (pagination) => {
    //Load data from DB and update to table
    setLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;
      fetchBulkPayment(token, pagination)
        .then((e) => {
          setLoading(false);
          setData({ bulkPayment: e.bulkPayment, count: e.count.aggregate.count });
        })
        .catch((e) => {
          notification.error({ message: e.message });
          console.error('Error occured', e);
          setLoading(false);
        });
    }
  };

  const deleteBulkLink = async (id, name) => {
    setLoading(true);
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult();
      const token = idTokenResult.token;
      deleteBulkLinkWrapper(id, token)
        .then((e) => {
          if (e.error) {
            notification.error({ message: e.error });
            setLoading(false);
          } else {
            notification.success({ message: `${name ? name : 'Record'} deleted` });
            updateTableData({ current: 1, pageSize: 10 });
          }
        })
        .catch((e) => {
          notification.error({ message: e.message });
          console.error('Error occured', e);
          setLoading(false);
        });
    }
  };

  const onAmountChange = (e) => {
    // set slider form amount field
    setSliderState((prev) => {
      return { ...prev, preRegistrationAmount: e.target.value };
    });
  };

  const onNameChange = (e) => {
    // set slider form name field
    setSliderState((prev) => {
      return { ...prev, name: e.target.value };
    });
  };

  const onLinkTypeChange = (e) => {
    // set slider form Link Type
    setSliderState((prev) => {
      return { ...prev, linkType: e };
    });
  };

  const onProgramChange = (e) => {
    // set slider form Program Name
    setSliderState((prev) => {
      return { ...prev, programName: e };
    });
  };

  const showDrawer = () => {
    //show slider
    setVisible(true);
  };

  const onClose = () => {
    // hide slider
    setVisible(false);
  };

  const onFinish = (values) => {
    // Handling form submition
    createBulkPaymnet(sliderState)
      .then((e) => {
        console.log(e);
        setSliderState({
          preRegistrationAmount: '1000',
          linkType: 'Regular',
          programName: 'Certification',
          name: '',
        });
        bulkLinkCreationNotificaton();
        updateTableData({ current: 1, pageSize: 10 });
        onClose();
      })
      .catch((e) => console.log(e));
  };

  //   This render has three parts 1) Table to list bulk link 2) Slider with form 3) Floating button to open slider
  return (
    <div
      style={{
        minHeight: '100vh',
      }}
    >
      {/* Table for bulck link showcase */}
      <Table
        columns={columns}
        dataSource={Array.isArray(data.bulkPayment) ? data.bulkPayment : []}
        style={{ overflow: 'scroll' }}
        onChange={(e) => updateTableData(e)}
        rowKey={(e) => e.id}
        pagination={{
          total: data.count,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50, 100],
        }}
        loading={loading}
      />

      {/* Slider component */}
      <Drawer
        title="Create Bulk link"
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <Form
          name="bulkPaymentGenerator"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <lable>Name</lable>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Enter name for this link',
              },
            ]}
          >
            <Input
              size="large"
              min="1000"
              placeholder="Name for bulk link"
              value={sliderState.name}
              name="name"
              onChange={onNameChange}
            />
          </Form.Item>

          <lable>Link Type</lable>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Select link type',
              },
            ]}
          >
            <Select
              defaultValue={sliderState.linkType}
              size="large"
              name="linkType"
              onChange={onLinkTypeChange}
            >
              <Option value="Regular">Regular</Option>
              <Option value="Dual Course">Dual Course</Option>
            </Select>
          </Form.Item>
          <lable>Program Name</lable>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Select Program Name',
              },
            ]}
          >
            <Select
              defaultValue={sliderState.programName}
              size="large"
              name="programName"
              onChange={onProgramChange}
            >
              <Option value="Certification">Certification</Option>
              <Option value="Internship">Internship</Option>
              <Option value="Job_guarantee">Job Guarantee</Option>
              <Option value="Kids">Kids</Option>
              <Option value="Xtra">Xtra</Option>
            </Select>
          </Form.Item>

          <lable>Pre-Registration amount (minimum ₹1000)</lable>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Enter pre registration amount minimum of ₹1000',
                min: '1000',
              },
            ]}
          >
            <Input
              size="large"
              prefix="₹"
              min="1000"
              placeholder="Pre Registration amount"
              value={sliderState.preRegistrationAmount}
              name="preRegistrationAmount"
              type="number"
              onChange={onAmountChange}
            />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" className="">
              Generate Link
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      {/* Floating button on bottom */}
      <Affix
        offsetBottom={30}
        style={{
          textAlign: 'right',
          color: 'black',
          marginRight: '10%',
        }}
      >
        <Avatar
          style={{
            backgroundColor: 'rgb(10, 103, 162)',
            color: 'White',
            boxShadow: '2px 2px 10px 2px  rgba(0,0,0,0.1)',
            '&:hover': {
              pointer: 'cursor',
              backgroundColor: 'red',
            },
          }}
          size={55}
          icon={<PlusOutlined />}
          onClick={showDrawer}
        />
      </Affix>
    </div>
  );
};

export default BulkPayment;
