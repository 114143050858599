const BACKEND_HASURA_URL = process.env.REACT_APP_HASURA_URL;

async function fetchDashboardRevenueAllTimeDetails(timestamp) {
  try {
    const result = await fetch(`${BACKEND_HASURA_URL}/dashboard`, {
      method: 'post',
      body: JSON.stringify({ monthTimestamp: timestamp }),
    });
    return await result.json();
  } catch (e) {
    console.log('Error occured', e);
    return { Error: e };
  }
}

const normalizeAggregate = (data) => {
  let newObject = {};
  if (data.error) return newObject;
  Object.keys(data).map((e) => {
    if (data[e].aggregate.sum)
      newObject[e + 'Amount'] = data[e].aggregate.sum.amount
        ? data[e].aggregate.sum.amount
        : data[e].aggregate.sum.pre_reg_amount;
    if (data[e].aggregate.count) newObject[e + 'Count'] = data[e].aggregate.count;
  });
  return newObject;
};

export async function fetchDashboardRevenueAllTime() {
  try {
    let timestamp = new Date();
    timestamp.setDate(1);
    timestamp.setHours(0, 0, 0, 0);
    timestamp = timestamp.toISOString();
    let data = await fetchDashboardRevenueAllTimeDetails(timestamp);

    return normalizeAggregate(data);
  } catch (e) {
    throw e;
  }
}
