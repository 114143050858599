import React from 'react';

import { Row, Col } from 'antd';
import DeleteModal from '../../Components/delete/deleteModal';

const SinglePaymentViewerDrawer = ({ sliderData, deleteCallback }) => {
  return (
    <Row>
      <Row>
        <Col span={18}>
          {' '}
          <h2>Student Details</h2>
        </Col>
        <Col span={6}>
          {' '}
          <DeleteModal
            callback={deleteCallback}
            id={sliderData.id}
            name={sliderData.customer_name}
          />
        </Col>
      </Row>

      <Row style={{ textAlign: 'left' }}>
        <Col span={12} style={{ fontWeight: '700' }}>
          Name :
        </Col>
        <Col span={12}>{sliderData.customer_name}</Col>
        <Col span={12} style={{ fontWeight: '700' }}>
          Email Id :
        </Col>
        <Col span={12}>{sliderData.email}</Col>
        <Col span={12} style={{ fontWeight: '700' }}>
          Phone No :
        </Col>
        <Col span={12}>{sliderData.phone_no}</Col>

        <Col
          span={12}
          style={{
            fontWeight: '700',
            borderTop: '1px solid rgba(0,0,0,0.3)',
            marginTop: '0.5rem',
            paddingTop: '0.5rem',
          }}
        >
          Link Type:
        </Col>
        <Col
          span={12}
          style={{
            borderTop: '1px solid rgba(0,0,0,0.3)',
            marginTop: '0.5rem',
            paddingTop: '0.5rem',
          }}
        >
          {sliderData.dual_course_name
            ? 'Dual'
            : 'Single' + sliderData.is_full_amount
            ? ' Full amount'
            : ''}
        </Col>

        <Col span={12} style={{ fontWeight: '700' }}>
          Program:
        </Col>
        <Col span={12}>{sliderData.program_name}</Col>

        {!sliderData.is_full_amount ? (
          <>
            <Col
              span={12}
              style={{
                fontWeight: '700',
                borderTop: '1px solid rgba(0,0,0,0.3)',
                marginTop: '0.5rem',
                paddingTop: '0.5rem',
              }}
            >
              Pre-Reg Amount :
            </Col>
            <Col
              span={12}
              style={{
                borderTop: '1px solid rgba(0,0,0,0.3)',
                marginTop: '0.5rem',
                paddingTop: '0.5rem',
              }}
            >
              {sliderData.pre_reg_amount}
            </Col>
          </>
        ) : null}
        <Col span={12} style={{ fontWeight: '700' }}>
          Total Amount :
        </Col>
        <Col span={12}>
          {sliderData.pre_reg_amount != ''
            ? parseInt(sliderData.pre_reg_amount) + parseInt(sliderData.amount)
            : parseInt(sliderData.amount)}
        </Col>
      </Row>

      <h2 style={{ marginTop: '1rem' }}>Course Details </h2>
      <Row>
        {sliderData.customer_course
          ? sliderData.customer_course.map((courseData) => (
              <>
                <Col span={12} style={{ fontWeight: '700' }}>
                  Name:
                </Col>
                <Col span={12}>{courseData.course}</Col>

                {courseData.program_plan ? (
                  <>
                    <Col span={12} style={{ fontWeight: '700' }}>
                      Plan:
                    </Col>
                    <Col span={12}>{courseData.program_plan}</Col>
                  </>
                ) : null}

                {courseData.batch_month ? (
                  <>
                    <Col span={12} style={{ fontWeight: '700' }}>
                      Batch :
                    </Col>
                    <Col span={12}>{courseData.batch_month}</Col>
                  </>
                ) : null}
              </>
            ))
          : null}
      </Row>

      <h2 style={{ marginTop: '1rem' }}>Transaction Details </h2>
      <Row>
        {sliderData.customer_transaction
          ? sliderData.customer_transaction.map((transData, index) => (
              <>
                <Col span={12} style={{ fontWeight: '700' }}>
                  ID:
                </Col>
                <Col span={12}>{transData.id}</Col>
                <Col span={12} style={{ fontWeight: '700' }}>
                  Order ID:
                </Col>
                <Col span={12}>{transData.order_id}</Col>
                <Col span={12} style={{ fontWeight: '700' }}>
                  Gateway:
                </Col>
                <Col span={12}>{transData.gateway}</Col>
                <Col span={12} style={{ fontWeight: '700' }}>
                  Amount:
                </Col>
                <Col span={12} style={{ color: transData.payment_success ? 'green' : 'red' }}>
                  {transData.amount / 100}
                </Col>
                <Col
                  span={12}
                  style={{
                    fontWeight: '700',
                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                    marginBottom: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                >
                  Status:
                </Col>
                <Col
                  span={12}
                  style={{
                    color: transData.payment_success ? 'green' : 'red',
                    fontWeight: 500,

                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                    marginBottom: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                >
                  {transData.payment_success ? 'PAID' : 'Failed'}
                </Col>
              </>
            ))
          : null}
      </Row>
    </Row>
  );
};

export default SinglePaymentViewerDrawer;
