import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
const Notfound404 = ({ height }) => {
  const minHeightValue = height ? '100vh' : '80vh';
  console.log(minHeightValue);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      style={{ minHeight: minHeightValue }}
    />
  );
};

export default Notfound404;
