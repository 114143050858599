import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const DeleteModal = ({ id, name, callback }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    callback(id, name);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button style={{ textDecoration: 'none', color: 'red' }} onClick={showModal}>
        Delete
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ danger: true }}
        okText={'Delete'}
      >
        <p>
          Do you want to delete {name ? <b style={{ color: 'red' }}>{name}</b> : 'this record'} ?
        </p>
      </Modal>
    </>
  );
};

export default DeleteModal;
