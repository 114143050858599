import { Row, Col, notification, Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import formData from './formData';
import { createSinglePaymnet } from './api';

import { Select } from 'antd';

const { Option } = Select;

//Main function of component
const LinkGenerator = () => {
  //Inital form data & used for reset form
  const initialStateData = {
    link: '',
    program: '',
    name: '',
    email: '',
    phoneNo: '',
    amount: '',
    preRegAmount: '',
    course: [{ name: '', plan: '', batch: '' }],
    fullAmount: false,
  };

  const [buttonLoading, setButtonLoading] = useState(false); // After clicking create button will have loading icon until reply
  const [data, setData] = useState(initialStateData); //Source of truth for form

  const handleSubmit = () => {
    setButtonLoading(true); // Initalte button loading
    const { link, program, name, email, phoneNo, amount, preRegAmount, course, fullAmount } = data;
    let missingValue = [];
    if (!link) {
      // If any data required is missing then added to the array and shown as notification except course part
      missingValue.push('Link Type');
    }
    if (!program) {
      missingValue.push('Program Name');
    }
    if (!name) {
      missingValue.push('Name');
    }
    if (!email) {
      missingValue.push('Email ID');
    }
    if (!phoneNo) {
      missingValue.push('phone no');
    }
    if (!fullAmount && !preRegAmount) {
      missingValue.push('Pre-reg Amount');
    }
    if (!amount) {
      missingValue.push('Amount');
    }

    if (course.filter((e) => e.name === '').length > 0) missingValue.push('Course');
    //Displaying error notification for missing values
    if (missingValue.length > 0) {
      let des = missingValue.join(', ');
      notification.error({
        message: `Following field are required`,
        description: `Please enter ${des} details`,
        duration: 3,
      });

      setButtonLoading(false);
      return;
    }
    //Based on no of course fields are checked and error notification shown
    if (
      course.length > 1 &&
      course[0].name === course[1].name &&
      course[0].plan === course[1].plan &&
      course[0].batch === course[1].batch
    ) {
      notification.error({
        message: `Course / Program plan / Batch  shoud be uniqe`,
        duration: 3,
      });
      setButtonLoading(false);
      return;
    }

    //All the validation done ready for creating link
    let inputData = {
      amount,
      customer_name: name,
      email,
      phone_no: phoneNo,
      program_name: program,
    };

    inputData['pre_reg_amount'] = preRegAmount ? preRegAmount : 0;
    inputData['is_full_amount'] = fullAmount ? true : false;
    inputData['dual_course_name'] = course.length > 1 ? 'Dual' : course[0].name;

    //API request initating to Hasura
    createSinglePaymnet(inputData, course)
      .then((e) => {
        console.log(e);
        setData(initialStateData);

        notification.success({
          message: 'User record created',
          duration: 3,
        });
        setButtonLoading(false);
      })
      .catch((e) => {
        notification.error({ message: e.message });
        console.log(e);
        setButtonLoading(false);
      });
  };

  //Handle change on course options
  function handleChange(value, index, field) {
    resetPayment();

    setData((prev) => {
      let course = prev.course;
      course[index][field] = value;
      return {
        ...prev,
        course,
      };
    });
  }

  //Link changes captured ie) Single / Dual course
  const handleLinkType = (e) => {
    resetPayment();

    if (e === 'single')
      setData((prev) => {
        return { ...prev, course: [{ name: '', plan: '', batch: '' }], fullAmount: false, link: e };
      });
    if (e === 'dual')
      setData((prev) => {
        return {
          ...prev,
          course: [
            { name: '', plan: '', batch: '' },
            { name: '', plan: '', batch: '' },
          ],
          fullAmount: false,
          link: e,
        };
      });
    if (e === 'fullpaymentSingle')
      setData((prev) => {
        return { ...prev, course: [{ name: '', plan: '', batch: '' }], fullAmount: true, link: e };
      });
    if (e === 'fullpaymentDual')
      setData((prev) => {
        return {
          ...prev,
          course: [
            { name: '', plan: '', batch: '' },
            { name: '', plan: '', batch: '' },
          ],
          fullAmount: true,
          link: e,
        };
      });
    console.log(e);
  };

  //Reset amount and preReg amount
  const resetPayment = (e) => {
    setData((prev) => {
      return { ...prev, amount: '', preRegAmount: '' };
    });
  };

  //Select programs
  const handelProgram = (e) => {
    resetPayment();

    handleLinkType(data.link);
    setData((prev) => {
      return { ...prev, program: e };
    });
  };

  /// Update amounts
  const updateAmount = (value, field) => {
    setData((prev) => {
      return { ...prev, [field]: value };
    });
  };

  return (
    <div style={{ minHeight: '80vh' }}>
      <h2 style={{ textAlign: 'left', borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
        Create Single link
      </h2>

      <Form
        // layout="inline"
        wrapperCol={{ span: 12 }}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        // onFinish={handleSubmit}
      >
        <Row>
          <Col sm={24} md={12} style={{ textAlign: 'left', paddingRight: '1rem' }}>
            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>Link Type</lable>{' '}
              <Select
                // defaultValue="single"
                placeholder="Select option"
                size="large"
                id="linkType"
                style={{ display: 'block' }}
                onChange={handleLinkType}
              >
                <Option value="single">Single Course</Option>
                <Option value="dual">Dual Course</Option>
                <Option value="fullpaymentSingle">Full Payment- Single Course</Option>
                <Option value="fullpaymentDual">Full Payment- Dual Course</Option>
              </Select>
            </div>
            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>Program Name</lable>{' '}
              <Select
                // defaultValue="single"
                placeholder="Select option"
                size="large"
                style={{ display: 'block' }}
                onChange={handelProgram}
              >
                {Object.keys(formData).map((program) => (
                  <Option value={program}>{program}</Option>
                ))}
              </Select>
            </div>

            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>Name</lable>{' '}
              <Form.Item
                rules={[{ required: true, message: 'Please input customer name!' }]}
                noStyle={true}
              >
                <Input
                  size="large"
                  required={true}
                  value={data.name}
                  onChange={(e) => updateAmount(e.target.value, 'name')}
                  placeholder="Enter Student Name"
                />
              </Form.Item>
            </div>

            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>Email</lable>{' '}
              <Form.Item
                rules={[{ required: true, message: 'Please input customer name!' }]}
                noStyle={true}
              >
                <Input
                  size="large"
                  value={data.email}
                  required={true}
                  onChange={(e) => updateAmount(e.target.value, 'email')}
                  placeholder="Enter Student email"
                />
              </Form.Item>
            </div>

            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>Phone Number</lable>{' '}
              <Form.Item
                noStyle={true}
                rules={[{ required: true, message: 'Please input customer name!' }]}
              >
                <Input
                  size="large"
                  value={data.phoneNo}
                  required={true}
                  onChange={(e) => updateAmount(e.target.value, 'phoneNo')}
                  placeholder="Enter Student Phone No."
                />
              </Form.Item>
            </div>
          </Col>
          <Col sm={24} md={12} style={{ textAlign: 'left', paddingRight: '1rem' }}>
            {data['course']
              ? data['course'].map((prog, index) => (
                  <React.Fragment key={index}>
                    <div style={{ paddingTop: '1rem' }}>
                      <lable style={{ fontWeight: 600, marginRight: '2px' }}>Course</lable>{' '}
                      <Select
                        value={data['course'][index].name ? data['course'][index].name : null}
                        placeholder="Select option"
                        size="large"
                        style={{ display: 'block' }}
                        onChange={(e) => handleChange(e, index, 'name')}
                      >
                        {(formData[data.program] ? formData[data.program]['course'] : []).map(
                          (program) => (
                            <Option value={program}>{program}</Option>
                          ),
                        )}
                      </Select>
                    </div>
                    <div style={{ paddingTop: '1rem' }}>
                      <lable style={{ fontWeight: 600, marginRight: '2px' }}>Program Plan</lable>{' '}
                      <Select
                        value={data['course'][index].plan ? data['course'][index].plan : null}
                        placeholder="Select option"
                        size="large"
                        style={{ display: 'block' }}
                        onChange={(e) => handleChange(e, index, 'plan')}
                      >
                        {(formData[data.program] ? formData[data.program]['plan'] : []).map(
                          (program) => (
                            <Option value={program}>{program}</Option>
                          ),
                        )}
                      </Select>
                    </div>
                    <div style={{ paddingTop: '1rem' }}>
                      <lable style={{ fontWeight: 600, marginRight: '2px' }}>Batch month</lable>{' '}
                      <Select
                        value={data['course'][index].batch ? data['course'][index].batch : null}
                        placeholder="Select option"
                        size="large"
                        style={{ display: 'block' }}
                        onChange={(e) => handleChange(e, index, 'batch')}
                      >
                        {(formData[data.program]
                          ? Object.keys(formData[data.program]['batch']).filter(
                              (e) => formData[data.program]['batch'][e].isactive,
                            )
                          : []
                        ).map((program) => (
                          <Option value={program}>{program}</Option>
                        ))}
                      </Select>
                    </div>
                  </React.Fragment>
                ))
              : null}

            <div style={{ paddingTop: '1rem' }}>
              <lable style={{ fontWeight: 600, marginRight: '2px' }}>
                Course Amount (Pitched Price)
              </lable>{' '}
              <Input
                size="large"
                value={data.amount}
                onChange={(e) => updateAmount(e.target.value, 'amount')}
                prefix={'₹'}
              />
            </div>
            {!data.fullAmount ? (
              <div style={{ paddingTop: '1rem' }}>
                <lable style={{ fontWeight: 600, marginRight: '2px' }}>
                  Pre-Registration Amount
                </lable>{' '}
                <Input
                  size="large"
                  value={data.preRegAmount}
                  onChange={(e) => updateAmount(e.target.value, 'preRegAmount')}
                  prefix={'₹'}
                />
              </div>
            ) : null}
          </Col>
        </Row>

        <div
          style={{
            display: 'block',
            marginTop: '1rem',
            width: '40%',
            marginLeft: '30%',
          }}
        >
          {' '}
          <Form.Item noStyle={true}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              className="login-form-button"
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default LinkGenerator;
