import { notification } from 'antd';
import { getUser } from '../../Components/auth/firebase';

const BACKEND_HASURA_URL = process.env.REACT_APP_HASURA_URL;

/* 
  1) fetchBulkPayment        - Employees can view the list of bulk link grenerated by all employees
  2) createBulkPaymnet       - Employees create new bulk link with pre-reg amount, course type &  Program name
  3) createSingleBulkPaymnet - Customers can update there details on the bulk link shared by employees
  4) fetchSingleBulkPayment  - Customers can get the details of bulk link shared by employees 
  5) fetchBulkLinkCustomers  - Employees can view the list of customers enrolled. 
*/

// --- | 1 | ------------ Get list of bulk payment link generated by Employees ---------
/* 
    This API is used when tha page is loaded so the token has to be passed on the call
    It returns the Array of links inserted via API  | 2 | by employees
 */
async function fetchBulkPaymentDetails(token, offset, limit) {
  const result = await fetch(`${BACKEND_HASURA_URL}/bulk-payment?offset=${offset}&limit=${limit}`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return await result.json();
}

export async function fetchBulkPayment(token, pagination) {
  let { current: offset, pageSize: limit } = pagination;
  offset = offset * limit - limit;
  let data = await fetchBulkPaymentDetails(token, offset, limit);
  return data;
}

// -----| 2 |-------------- Insert the Bulk payment Link generated by Employee ----------

/* 
    Create and insert the bulk payment with pre registerd amount and the basic course details by employee

    This API required valid employee name. If employee name is not updated the it will prompt the same.
*/
async function insertBulkPayment(name, amount, link, program) {
  const { displayName: empName, accessToken: token } = getUser();
  if (empName === null) {
    notification.error({ message: 'Please update your name in profile to proceed further' });
    throw Error('Employee name Required');
  }
  const result = await fetch(`${BACKEND_HASURA_URL}/bulk-payment/insert`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify({
      created_by: empName,
      link: link,
      pre_reg_amount: amount,
      program: program,
      name: name,
    }),
  });

  return await result.json();
}

export async function createBulkPaymnet(reqData) {
  const { name, preRegistrationAmount, linkType, programName } = reqData;
  let data = await insertBulkPayment(name, preRegistrationAmount, linkType, programName);
  return data;
}

// ---| 3 |------------------------ (Unprotected) Create new record for customer------------

/*
  After customer reciving the bulk link customer can populate there details in cutomerDetails.
  This details will be updated by the customer itself - Unprotected API call.
*/
async function insertSingleBulkPayment(inputData) {
  const result = await fetch(`${BACKEND_HASURA_URL}/customer/bulk-payment/inset`, {
    method: 'POST',
    body: JSON.stringify({
      created_by: 'public',
      bulk: true,
      ...inputData,
    }),
  });

  return await result.json();
}

export async function createSingleBulkPaymnet(inputData, course) {
  let response = await insertSingleBulkPayment(inputData);
  const customerId = response['insert_customer_details_one']
    ? response['insert_customer_details_one'].id
    : response;

  return customerId;
}

//---| 4 |------------------------------ (Unprotected) Get details feed by employees for bulk customer creation---------

/* 
  After employee sharing the link to customer, When the customer clickes the link the bulk link details will be 
  shared by this API.

  As the customer facing data this API is unprotected.
*/

async function fetchSingleBulkPaymentDetails(id) {
  const result = await fetch(`${BACKEND_HASURA_URL}/bulk-payment/${id}`, {
    method: 'get',
  });
  return await result.json();
}

export async function fetchSingleBulkPayment(id) {
  let data = await fetchSingleBulkPaymentDetails(id);
  return data;
}

//---| 5 |-- Fetch list of customers registerd by the bulk link created by employees --------------------

/*
  After Customer updatedthe details in the bulk link, Irrespective of payment status employees 
  can view the status using this API.
*/
async function fetchBulkLinkCustomersDetails(token, offset, limit, query) {
  const result = await fetch(
    `${BACKEND_HASURA_URL}/bulk-link-customer?offset=${offset}&limit=${limit}${query}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    },
  );
  return await result.json();
}

export async function fetchBulkLinkCustomers(token, pagination, query) {
  let { current: offset, pageSize: limit } = pagination;
  offset = offset * limit - limit;
  let data = await fetchBulkLinkCustomersDetails(token, offset, limit, query);
  return data;
}

/*
  Delete the bulk link created by employees
*/
async function deleteBulkPaymantLink(id, token) {
  const result = await fetch(`${BACKEND_HASURA_URL}/bulk-payment-link/${id}`, {
    method: 'delete',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return await result.json();
}

export async function deleteBulkLinkWrapper(id, token) {
  let data = await deleteBulkPaymantLink(id, token);
  return data;
}

/*
  Delete the record created by users using the bulk link
*/
async function deleteCustomerBulkPaymantLink(id, token) {
  const result = await fetch(`${BACKEND_HASURA_URL}/customer/${id}`, {
    method: 'delete',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return await result.json();
}

export async function deleteCustomerBulkLinkWrapper(id, token) {
  let data = await deleteCustomerBulkPaymantLink(id, token);
  return data;
}
