import { Layout } from 'antd';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../Components/notFound/notFound';
import CustomerPaymentPage from '../Pages/customer/customerPayment';
import CustomerPaymentSuccess from '../Pages/customer/customerPaymentSuccess';
import BulkPaymentCustomer from '../Pages/bulkPayments/bulkPaymentCustomer';
import TopNavbar from '../Components/navigation/topNavbar';

const { Content } = Layout;

const CustomerRouter = () => {
  return (
    <>
      <TopNavbar nav={false} />

      <Routes>
        <Route path="/:id" element={<CustomerPaymentPage />} />
        <Route path="/details/:id" element={<BulkPaymentCustomer />} />
        <Route path="/success/:id" element={<CustomerPaymentSuccess />} />
        <Route path="*" element={<NotFound height={false} />} />
      </Routes>
    </>
  );
};

export default CustomerRouter;
