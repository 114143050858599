import { Routes, Route } from 'react-router-dom';
import EmpRouter from './empRouter';
import NotFound from '../Components/notFound/notFound';
import CustomerRouter from './customerRouter';
import LogIn from '../Pages/auth/login';

let classNameCond = document.getElementsByTagName('body')[0].offsetWidth < 500 ? 'mainRouter' : '';

const MainRouter = () => {
  return (
    <div className={classNameCond}>
      <Routes>
        <Route path="/customer/*" element={<CustomerRouter />} />
        <Route path="/employee/*" element={<EmpRouter />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="*" element={<NotFound height={true} />} />
      </Routes>
    </div>
  );
};

export default MainRouter;
