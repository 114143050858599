import React, { useEffect, useState } from 'react';
import { Form, Icon, Input, Button, Select } from 'antd';
const { Option } = Select;

const Filter = ({ filters, limit, offset, updateTableData }) => {
  let [filter, setFilter] = useState({ type: filters[0] ? filters[0] : '', value: '' });

  const handleSubmit = () => {
    const filterQuery = { [filter.type]: filter.value };
    console.log('Received values of form: ', filterQuery);

    let filterQueryString = '';

    const filterKeys = Object.keys(filterQuery);
    filterKeys.map((e) => (filterQueryString += `&${e}=%25${filterQuery[e]}%25`));
    console.log(filterQueryString);

    updateTableData({ current: offset, pageSize: limit }, filterQueryString);
  };

  // Only show error after a field is touched.

  return (
    <div>
      <Form layout="inline" style={{ justifyContent: 'right', textAlign: 'left', marginRight: 0 }}>
        <Form.Item>
          <Select
            style={{ minWidth: '10em' }}
            value={filter.type}
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, type: e };
              })
            }
          >
            {filters.map((e) => (
              <Option key={e} value={e}>
                {e.charAt(0).toUpperCase() + e.slice(1)}
              </Option>
            ))}
          </Select>
          ,
        </Form.Item>
        <Form.Item>
          <Input
            type="text"
            placeholder="Filter"
            value={filter.value}
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, value: e.target.value };
              })
            }
          />
          ,
        </Form.Item>
        <Form.Item style={{ marginRight: 0 }}>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Filter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Filter;
