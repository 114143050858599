import './App.css';
import MainRouter from './Routes/mainRouter';
import { Layout } from 'antd';
import TopNavbar from './Components/navigation/topNavbar';

function App() {
  return (
    <div className="App">
      <Layout>
        <MainRouter />
      </Layout>
    </div>
  );
}

export default App;
