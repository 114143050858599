const formData = {
  Internship: {
    course: [
      'Machine learning',
      'Cyber security',
      'Azure cloud computing',
      'Data science',
      'Artificial intelligence',
      'Augmented reality and virtual reality',
      'Android app development',
      'App development with React native',
      'UX/UI design',
      'Front end web development with React js',
      'Front end web development with Angular js',
      'Back end web development with MongoDB using Node js',
      'Back end web development with MongoDB using Django',
      'Back end web development with MySQL with Springboot',
      'Robotics',
      'Internet Of Things',
      'Hybrid and electric vehicles',
      'Embedded systems',
      'Autocad',
      'Car designing',
      'IC engine',
      'Construction planning',
      'Bitcoin/block chain',
      'Marketing management',
      'Finance',
      'Digital marketing',
      'Business analytics',
      'Stock marketing',
      'Human resource',
      'Nanoscience and Nanotechnology',
      'Genetic engineering',
      'Front end(React js)+back end(Node js)',
      'Front end(Angular js)+back end(Node js)',
      'Front end(React js)+back end(MySQL - Spring boot)',
      'Front end(Angular js)+back end(MySQL - Spring boot)',
      'Graphic designing',
      'Front end(React js)+back end(django)',
      'Front end(Angular js)+back end(django)',
      'Bioinformatics',
      'VLSI',
      'AWS',
      'Web development with IBM',
      'Machine learning with IBM',
      'Data science with IBM',
      'Artificial intelligence with IBM',
      'Internet Of Things with IBM',
      'Robotics with IBM',
      'Deep learning with IBM',
      'Embeded system with IBM ',
      'Python with IBM',
      'App development with IBM',
    ],
    plan: ['Self placed', 'Mentor led', 'Advanced'],
    batch: {
      January: {
        isactive: true,
      },
      February: {
        isactive: true,
      },
      March: {
        isactive: true,
      },
      April: {
        isactive: false,
      },
      May: {
        isactive: false,
      },
      June: {
        isactive: false,
      },
      July: {
        isactive: false,
      },
      August: {
        isactive: false,
      },
      September: {
        isactive: false,
      },
      October: {
        isactive: false,
      },
      November: {
        isactive: false,
      },
      December: {
        isactive: false,
      },
    },
  },
  Certification: {
    course: [
      'Full stack web development',
      'Artificial intelligence',
      'Data science',
      'Machine learning',
      'Marketing management & digital marketing',
      'Digital marketing',
      'Finance',
      'Devops',
      'Flutter dart',
      'Human resource',
    ],
    plan: ['Gold', 'Silver', 'Diamond'],
    batch: {
      January: {
        isactive: true,
      },
      February: {
        isactive: true,
      },
      March: {
        isactive: true,
      },
      April: {
        isactive: false,
      },
      May: {
        isactive: false,
      },
      June: {
        isactive: false,
      },
      July: {
        isactive: false,
      },
      August: {
        isactive: false,
      },
      September: {
        isactive: false,
      },
      October: {
        isactive: false,
      },
      November: {
        isactive: false,
      },
      December: {
        isactive: false,
      },
    },
  },
  Job_guarantee: {
    course: [
      'Marketing management & digital marketing',
      'Marketing management',
      'Human resource',
      'Full stack web development',
      'Business analytics',
      'Selenium',
    ],
    plan: [],
    batch: {
      January: {
        isactive: true,
      },
      February: {
        isactive: true,
      },
      March: {
        isactive: true,
      },
      April: {
        isactive: false,
      },
      May: {
        isactive: false,
      },
      June: {
        isactive: false,
      },
      July: {
        isactive: false,
      },
      August: {
        isactive: false,
      },
      September: {
        isactive: false,
      },
      October: {
        isactive: false,
      },
      November: {
        isactive: false,
      },
      December: {
        isactive: false,
      },
    },
  },
};

export default formData;
