import { Layout, Spin } from 'antd';
import SideNavbar from '../Components/navigation/sideNavbar';
import { Routes, Route } from 'react-router-dom';
import LinkGenerator from '../Pages/payment/linkGenerator';
import BulkPayment from '../Pages/bulkPayments/bulkPayment';
import BulkPaymentViewer from '../Pages/bulkPayments/bulkPaymentViewer';
import NotFound from '../Components/notFound/notFound';
import SinglePaymentViewer from '../Pages/payment/singlePaymentViewer';
import Dashboard from '../Pages/dashboard/dashboard';
import TopNavbar from '../Components/navigation/topNavbar';
import Profile from '../Pages/auth/profile';
import React, { useState } from 'react';

import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../Components/auth/firebase';

const { Content } = Layout;

const EmpRouter = () => {
  const [collapsed, setCollapsed] = useState(true);

  const currentUser = useAuth();
  console.log('empRouter', currentUser);

  if (currentUser === undefined)
    return (
      <div style={{ height: '100vh', position: 'relative' }}>
        <Spin
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
          spinning={true}
        ></Spin>
      </div>
    );
  else if (currentUser === null)
    return (
      <Routes>
        {' '}
        <Route path="*" element={<Navigate replace to={'/login'} />} />
      </Routes>
    );
  else
    return (
      <>
        <TopNavbar nav={true} collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          <SideNavbar collapsed={collapsed} />
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-link" element={<LinkGenerator />} />
                <Route path="/form-status" element={<SinglePaymentViewer />} />
                <Route path="/form-bulk-link" element={<BulkPayment />} />
                <Route path="/form-bulk-link-status" element={<BulkPaymentViewer />} />
                <Route path="/profile" element={<Profile user={currentUser} />} />
                <Route path="*" element={<NotFound height={false} />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </>
    );
};

export default EmpRouter;
