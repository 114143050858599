import { notification } from 'antd';
import { getUser } from '../../Components/auth/firebase';

const BACKEND_HASURA_URL = process.env.REACT_APP_HASURA_URL;

/* 
  1)  fetchSinglePayment   - Get the list of links created by employees for customer
  2)  createSinglePaymnet  - Employees can create a link for customer
  3)  fetchCustomer        - Get detiailed info of each customer based on there ID.
*/

//---[1]---------- Get list of single payment link generated by employees ---------

/*
  Get the list of all the course registration link generated by all the employees 
  specifically for a single user

  Token has to be passed as this is loading API call
*/
async function fetchSinglePaymentDetails(token, page, limit, query) {
  const result = await fetch(
    `${BACKEND_HASURA_URL}/singe-link?offset=${page * limit - limit}&limit=${limit}${query}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    },
  );
  return await result.json();
}

export async function fetchSinglePayment(token, page, limit, query) {
  let data = await fetchSinglePaymentDetails(token, page, limit, query);
  return data;
}

//---[2]----------- Create single payment for specific user by employee ------------

/* 

    Employees can create customer course link using that link user can pay / pre pay and confirm the booking.
    
    First customer data is inserterd then using that ID courses are inserted with forgen key relation. 
*/

async function insertSinglePayment(inputData, empName, token) {
  if (empName === null) {
    notification.error({ message: 'Please update your name in profile to proceed further' });
    throw Error('Employee name Required');
  }

  const result = await fetch(`${BACKEND_HASURA_URL}/singe-link/insert`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify({
      created_by: empName,
      bulk: false,
      ...inputData,
    }),
  });

  return await result.json();
}

async function insertCourses(courseList, token) {
  const result = await fetch(`${BACKEND_HASURA_URL}/course/insert`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify({
      ...courseList,
    }),
  });
  return await result.json();
}

export async function createSinglePaymnet(inputData, course) {
  const { displayName: empName, accessToken: token } = getUser();

  let response = await insertSinglePayment(inputData, empName, token);
  console.log(response);
  const customerId = response['insert_customer_details_one'].id;

  let courseId = [];
  if (customerId) {
    const courseList = course.map(async (e) => {
      insertCourses(
        {
          batch_month: e.batch,
          course: e.name,
          customer_id: customerId,
          program_plan: e.plan,
        },
        token,
      ).then((e) => courseId.push(e.insert_course.returning[0].id));
    });
  }

  return courseId;
}

//-----[3]------------ Get detailed user info by employee -----

/* 
  Using this API employees can view all the customer related infos including customer, transactions & course details
*/

async function fetchCustomerDetails(id) {
  const { accessToken: token } = getUser();
  const result = await fetch(`${BACKEND_HASURA_URL}/customer/all-details/${id}`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return await result.json();
}

export async function fetchCustomer(id) {
  let data = await fetchCustomerDetails(id);
  return data;
}

/*
  Delete the customer record created by the employees
*/
async function deleteCustomerBulkPaymantLink(id, token) {
  const result = await fetch(`${BACKEND_HASURA_URL}/customer/${id}`, {
    method: 'delete',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return await result.json();
}

export async function deleteCustomerBulkLinkWrapper(id, token) {
  let data = await deleteCustomerBulkPaymantLink(id, token);
  return data;
}
