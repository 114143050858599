import React, { useRef, useState } from 'react';
import { useAuth, login } from '../../Components/auth/firebase';
import { Form, Input, Button, Checkbox, Space, notification, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, Route, Routes, Navigate } from 'react-router-dom';
import TopNavbar from '../../Components/navigation/topNavbar';

const LogIn = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const { email, password } = values;
    console.log(values);
    //Admin2@hari
    login(email, password)
      .then((res) => {
        notification.success({
          message: 'Logged In Successfully...!',
        });
      })
      .catch((err) => {
        const errorCode = err.code;
        let displayMsg;
        switch (errorCode) {
          case 'auth/user-not-found':
            displayMsg = 'username / password is invalid';
            break;
          case 'auth/network-request-failed':
            displayMsg = 'Internet not available';
            break;

          default:
            displayMsg = 'Some error occured';
        }
        notification.error({
          message: displayMsg,
        });
      });

    setLoading(false);
  };

  const currentUser = useAuth();

  return currentUser ? (
    <>
      <Routes>
        {' '}
        <Route path="*" element={<Navigate replace to={'/employee/dashboard'} />} />
      </Routes>
    </>
  ) : (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      <TopNavbar nav={false} />
      <div style={{ verticalAlign: 'center' }}>
        <div
          style={{
            minWidth: '15rem',
            maxWidth: '20rem',

            height: '50%',
            overflow: 'auto',
            margin: 'auto',
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            backgroundColor: 'white',
            padding: '2% 2%',
          }}
        >
          <h1
            style={{
              borderBottom: '1px solid rgba(0,0,0,0.3)',
              paddingBottom: '1%',
              marginBottom: '10%',
            }}
          >
            Login
          </h1>
          <Spin spinning={loading}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              style={{ aligItems: 'center' }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your Login Email!' }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email ID"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                  loading={loading}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
